import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
// const source_ip = window.location.hostname
// const source_port = 8090
const baseURL = import.meta.env.VITE_API_BASE_URL || `https://irebox-web.manosens.com/api`
const token = localStorage.getItem('token')

const axiosInstance = axios.create({
  baseURL,
})

if (token && token !== 'undefined') {
  const decodedToken = jwtDecode(token)
  const currentTime = Math.floor(Date.now() / 1000)

  if (decodedToken.exp != null && decodedToken.exp < currentTime) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  } else {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}

// export const verify_token = async () => {
//   // used for verifying user NOT IN USE
//   axiosInstance.defaults.withCredentials = true
//   axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
//   const result = await axiosInstance.post('/auth/verify', {
//     "token": localStorage.getItem('token')
//   })
//   console.log(result.status)
//   return result.status === 200;
// }

export const post_login = async (credentials: { email: string; password: string }) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
  return await axiosInstance.post('/auth/jwt/login', {
    username: credentials.email,
    password: credentials.password,
  })
}

export const logout = async () => {
  if (!localStorage.getItem('token')) return
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.post('/auth/jwt/logout')
}

export const get_user = async () => {
  if (!localStorage.getItem('token')) return { status: 401 }
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get('/users/me')
}

export const register_user = async (credentials: { email: string; password: string }) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  console.log(credentials)
  return await axiosInstance.post('/auth/register', {
    email: credentials.email,
    password: credentials.password,
  })
}

export default axiosInstance

import { DirectiveBinding } from 'vue'

interface ResizeDirectiveHTMLElement extends HTMLElement {
  zoom: number
  __resizeHandler__: () => void
}

const resizeDirective = {
  mounted(el: ResizeDirectiveHTMLElement, binding: DirectiveBinding) {
    const resizeHandler = () => {
      if (window.innerWidth < 600) {
        el.zoom = 0.5
      } else if (window.innerWidth < 1024) {
        el.zoom = 0.7
      } else {
        el.zoom = 1
      }
      if (binding.instance) {
        binding.instance.$forceUpdate()
      }
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)

    el.__resizeHandler__ = resizeHandler
  },
  unmounted(el: ResizeDirectiveHTMLElement) {
    window.removeEventListener('resize', el.__resizeHandler__)
  },
}

export default resizeDirective

import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'
import { Bms, BmsData } from './bms'

export interface Measurement {
  value: number | null | boolean
  unit: string
}

export interface DeviceMeasurements {
  [key: string]: Measurement
}
export interface RegulationStatus {
  [key: string]: Measurement
}

export interface DataItem {
  smart_meter: DeviceMeasurements
  inverter: DeviceMeasurements
  regulation: RegulationStatus
}

export interface BmsDataIn {
  bms_id: number
  soc: number
  config: Bms
}

export interface FullData {
  smart_meter_data: DeviceMeasurements
  inverter_data: DeviceMeasurements
  regulation_data: RegulationStatus
  battery_data: BmsDataIn[]
}
export const useBmsStore = defineStore('bmsStore', () => {
  const bmsData: Ref<BmsData> = ref({})

  return { bmsData }
})
export const useInverterStore = defineStore('inverterStore', () => {
  const data: Ref<DataItem> = ref({
    smart_meter: {
      Pt: { value: null, unit: 'kW' },
      Pa: { value: null, unit: 'kW' },
      Pb: { value: null, unit: 'kW' },
      Pc: { value: null, unit: 'kW' },
      'Phase 1 current': { value: null, unit: 'A' },
      'Phase 2 current': { value: null, unit: 'A' },
      'Phase 3 current': { value: null, unit: 'A' },
    },
    inverter: {
      'Total active power': { value: null, unit: 'kW' },
      'L1 Active power': { value: null, unit: 'kW' },
      'L2 Active power': { value: null, unit: 'kW' },
      'L3 Active power': { value: null, unit: 'kW' },
      'L1 Voltage': { value: null, unit: 'V' },
      'L2 Voltage': { value: null, unit: 'V' },
      'L3 Voltage': { value: null, unit: 'V' },
    },
    regulation: {
      'Regulation status': { value: false, unit: '' },
      'Regulation power': { value: null, unit: 'Kw' },
    },
  })
  const bmsStore = useBmsStore()
  let socket: WebSocket | null = null

  const fillData = (newData: FullData) => {
    data.value.smart_meter = {
      Pt: { value: +newData.smart_meter_data.pt, unit: 'kW' },
      Pa: { value: +newData.smart_meter_data.pa, unit: 'kW' },
      Pb: { value: +newData.smart_meter_data.pb, unit: 'kW' },
      Pc: { value: +newData.smart_meter_data.pc, unit: 'kW' },
      'Phase 1 current': { value: +newData.smart_meter_data.p1_current, unit: 'A' },
      'Phase 2 current': { value: +newData.smart_meter_data.p2_current, unit: 'A' },
      'Phase 3 current': { value: +newData.smart_meter_data.p3_current, unit: 'A' },
    }
    // data.value.regulation = newData.regulation
    data.value.inverter = {
      'Total active power': { value: +newData.inverter_data.total_active_power, unit: 'kW' },
      'L1 Active power': { value: +newData.inverter_data.l1_active_power, unit: 'kW' },
      'L2 Active power': { value: +newData.inverter_data.l2_active_power, unit: 'kW' },
      'L3 Active power': { value: +newData.inverter_data.l3_active_power, unit: 'kW' },
      'L1 Voltage': { value: +newData.inverter_data.l1_voltage, unit: 'V' },
      'L2 Voltage': { value: +newData.inverter_data.l2_voltage, unit: 'V' },
      'L3 Voltage': { value: +newData.inverter_data.l3_voltage, unit: 'V' },
    }
    newData.battery_data.forEach((bmsData) => {
      bmsStore.bmsData[bmsData.bms_id] = bmsData.config
    })
  }

  const connectWebSocket = () => {
    // const source_ip = window.location.hostname
    // const source_ip = 'irebox.local'
    // const source_port = 8090
    const source_ws = 'wss://irebox-web.manosens.com/ws/dashboard?sn=9cf2779a-b4d2-42fe-a688-8319d60f6fca'
    socket = new WebSocket(source_ws)

    socket.onmessage = (event) => {
      const newData: FullData = JSON.parse(event.data)
      // Môžete pridať logiku na aktualizáciu konkrétnych hodnôt podľa prichádzajúcich dát
      fillData(newData)
      // data.value.regulation = newData.regulation
    }

    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
    }

    socket.onclose = () => {
      console.log('WebSocket connection closed. Reconnecting...')
      setTimeout(connectWebSocket, 1000) // Znovu pripojenie po 1 sekunde
    }
  }

  return {
    data,
    connectWebSocket,
  }
})
